.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 400px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid;
  z-index: 9999;
  padding: 20px;
}
.custom-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent overlay */
  z-index: 9998; /* Ensure the overlay appears beneath the modal */
}